const cookieconsentOptions = {
    "palette": {
        "popup": {
            "background": "#edeff5",
            "text": "#838391"
        },
        "button": {
            "background": "#1465af"
        }
    },
    //"theme": "edgeless", // classic, edgeless, wire
    //"position": "bottom-right", // bottom-left, bottom-right, top, leer (banner unten)
    //"static": false, // absolute oder fixed
    "content": {
        "message": "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. \nDurch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer",
        "dismiss": "Verstanden!",
        "link": "Datenschutzbestimmung",
        "href": `${window.BASEURL}${window.LANGUAGE}/datenschutz`
    }
}

window.addEventListener("load", function(){ window.cookieconsent.initialise(cookieconsentOptions) })
